import Feed from "@/views/Feed.vue";

const privateRoutes = [
  {
    path: "/feed",
    name: "Feed",
    component: Feed,
    meta: { requiresAuth: true },
  },
];

export default privateRoutes;
