import { jwtDecode } from 'jwt-decode';

export function decodeToken() {
    const token = localStorage.getItem("SRPlatformJwtToken");
    let decodedToken = null;

    if (typeof token === 'string') {
        try {
            decodedToken = jwtDecode(token);
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    }

    return {
        token: token,
        decodedToken: decodedToken
    };
}

export function checkAuth() {
    const { decodedToken } = decodeToken();
    return decodedToken && decodedToken.exp && decodedToken.exp * 1000 > Date.now();
}

