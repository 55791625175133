import Vue from "vue";
import VueRouter from "vue-router";
import { checkAuth } from "@/utils/jwtUtils";

import publicRoutes from "./modules/public";
import privateRoutes from "./modules/private";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				selector: to.hash,
			};
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes: [
		...publicRoutes,
		...privateRoutes
	],
});

router.beforeEach((to, from, next) => {
	const isAuthenticated = checkAuth();

	if (to.matched.some((route) => route.meta.requiresAuth)) {
		if (isAuthenticated) {
			next();
		} else {
			next("/uloguj-se");
		}
	} else {
		next();
	}
});

export default router;
