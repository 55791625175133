<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>

    <section class="bg_lighgrey" style="min-height: 80vh">
      <div class="container">
        <div class="row px-3">
          <!-- FEED CONTENT -->
          <div class="col-9">
            <div class="row pt-4">
              <!-- PROFILE OVERVIEW -->
              <div v-if="researcher" class="col-12 card border-0">
                <div class="row py-3">
                  <div class="col-12 my-auto">
                    <img v-if="researcher.profile_image" class="rounded-circle mt-2 mr-3"
                         style="float: left"
                         :src="researcher.profile_image"
                         width="70"
                         height="70"
                         alt=""
                    />
                    <img v-else class="rounded-circle mt-2 mr-3"
                         style="float: left"
                         :src="require(`@/assets/default.png`)"
                         width="70"
                         height="70"
                         alt=""
                    />
                    <h5 class="my-2">
                      {{ researcher.first_name }}
                      {{ researcher.last_name }}
                    </h5>
                    <p class="text_s mb-2">
                      {{researcher.city}}, {{researcher.country}}
                    </p>
                    <p class="text_s mb-2">
                      <router-link to="#informacije-profila" data-toggle="collapse" data-target="#informacije-profila" aria-expanded="false" aria-controls="sidebar">
                        <u>Informacije profila</u>
                      </router-link>
                    </p>
                    <router-link to="#podesavanja" data-toggle="collapse" data-target="#podesavanja" aria-expanded="false" aria-controls="sidebar"
                                 style="position: absolute; top:0; right: 15px">
                      <img class="img-fluid" :src="require('@/assets/icons/filters.png')"
                           alt="Podešavanja" width="20" >
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row py-4 card my-3">
              <!-- INVITATIONS -->
              <div class="col-12">
                <div class="row pb-3">
                  <h5 class="col">Vaše pozivnice</h5>
                  <div class="col text-right">
                    <router-link type="button" class="btn button_red-invite" to="#nova-pozivnica" data-toggle="collapse" data-target="#nova-pozivnica" aria-expanded="false" aria-controls="sidebar">
                      + nova pozivnica
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0 mb-n4">
                <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    position: 'bottom',
                    setCurrentPage: 1,
                    nextLabel: 'sledeća',
                    prevLabel: 'prethodna'
                    }">
                  <div slot="emptystate" class="text-center">
                    Nemate aktivnih pozivnica.
                  </div>
                  <template slot="table-row" slot-scope="props"></template>
                </vue-good-table>
              </div>
            </div>
          </div>

          <!-- SIDEBAR -->
          <div class="col-3">
            <div class="row pt-4 pl-4">
              <!-- USEFUL LINKS -->
              <div class="col-12 card border-0 py-3">
                <h6 class="text_m mb-0">Korisni linkovi</h6>
                <hr>
                <p class="text_xs mb-2">
                  <router-link to="/blog">
                    <u>Najnovije vesti</u>
                  </router-link>
                </p>
                <p class="text_xs mb-2">
                  <a :href="`mailto:${infoEmail}?subject=${infoEmailSubject}`">
                    <u>Kontaktirajte nas</u>
                  </a>
                </p>
                <p class="text_xs mb-2 text-decoration-underline">
                  <router-link to="/faq">
                    <u>Često postavljana pitanja</u>
                  </router-link>
                </p>
              </div>
            </div>
          </div>

          <!-- DRAWERS -->
          <div class="col-12">
            <div id="serbian_researchers-drawers">
              <!-- INFORMACIJE PROFILA -->
              <div class="collapse width" id="informacije-profila">
                <div class="card card-body sidebar-body">
                  <form v-if="researcher" class="sr_form-drawer">
                    <div class="row text_s">
                      <h6 class="col text-left text_bold">
                        Informacije profila
                      </h6>
                      <router-link to="" class="col text-right text_m" data-toggle="collapse"
                         data-target="#informacije-profila" aria-expanded="false"
                         aria-controls="sidebar" style="cursor: pointer">
                        X
                      </router-link>
                    </div>
                    <div class="row px-3">
                      <hr class="col-12 my-2">
                    </div>
                    <fieldset disabled class="row mb-5">
                      <!-- OSNOVNE INFORMACIJE -->
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Ime</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.first_name">
                      </div>
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Prezime</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.last_name">
                      </div>
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Grad</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.city">
                      </div>
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Država</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.country">
                      </div>
                      <div class="col-12">
                        <hr>
                      </div>
                      <!-- INFORMACIJE O ISTRAŽIVAČU -->
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Naziv pozicije</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.position_old ? researcher.position_old : researcher.position_name">
                      </div>
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Tip institucije</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.origin">
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Naziv institucije</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.institution_name">
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Lična stranica</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.personal_page">
                      </div>
                      <div class="col-12">
                        <hr>
                      </div>
                      <!-- INFORMACIJE O ISTRAŽIVAČKOM RADU -->
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Oblast istraživanja</label>
                        <select class="form-control">
                          <option>{{researcher.research_area_name}}</option>
                        </select>
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Podblast istraživanja</label>
                        <select class="form-control">
                          <option>{{researcher.research_subarea_name}}</option>
                        </select>
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Teme istraživanja</label>
                        <select class="form-control">
                          <option>prosiri api response</option>
                        </select>
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Stranica sa radovima</label>
                        <input type="text" class="form-control w-100 text_s" :value="researcher.work_page">
                      </div>
                    </fieldset>
                  </form>
                  <div v-else>
                    <p class="row text_s px-3">
                      <i>Došlo je do greške, molimo Vas osvežite stranicu.</i>
                    </p>
                  </div>
                </div>
              </div>
              <!-- PODEŠAVANJA -->
              <div class="collapse width" id="podesavanja">
                <div class="card card-body sidebar-body">
                  <form v-if="researcher" class="sr_form-drawer">
                    <div class="row text_s">
                      <h6 class="col text-left text_bold">
                        Podešavanja
                      </h6>
                    </div>
                    <div class="row px-3">
                      <hr class="col-12 my-2">
                    </div>
                    <fieldset class="row">
                      <!-- PODEŠAVANJA -->
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Email adresa</label>
                        <input type="text" class="form-control w-100 text_s" v-model="researcher.email">
                      </div>
                      <div class="form-group col-12 mb-">
                        <label class="text_s mb-0">Adresa profila</label>
                        <input type="text" class="form-control w-100 text_s" v-model="researcher.profile_url">
                      </div>

                      <div v-if="isAccountUpdated" class="col-12 mb-0">
                        <p class="text_xs text_xs alert alert-success mb-0 p-2 rounded-2 mb-0">
                          {{this.accountMessage}}
                        </p>
                      </div>
                      <div class="col-12 my-3">
                        <button to="" type="button" class="btn button_red-small mr-2" style="font-size:12px"
                                @click="saveAccountInfo(researcher.researcher_id)">
                          Pošalji
                        </button>
                        <router-link type="button" class="btn button_red-cancel" style="font-size:12px" to="" data-toggle="collapse" data-target="#podesavanja" aria-expanded="false" aria-controls="sidebar">
                          Otkaži
                        </router-link>
                      </div>
                    </fieldset>
                  </form>
                  <div v-else>
                    <p class="row text_s px-3">
                      <i>Došlo je do greške, molimo Vas osvežite stranicu.</i>
                    </p>
                  </div>
                </div>
              </div>
              <!-- NOVA POZIVNICA -->
              <div class="collapse width" id="nova-pozivnica">
                <div class="card card-body sidebar-body">
                  <div class="row text_s">
                    <h6 class="col text-left text_bold">
                      Nova pozivnica
                    </h6>
                  </div>
                  <div class="row px-3">
                    <hr class="col-12 my-2">
                  </div>
                  <form class="row sr_form-drawer">
                    <p class="col-12 text_s">
                      Unesite podatke osobe kojoj želite da pošaljete personalizovanu pozivnicu za članstvo.
                    </p>
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Ime <span style="color: red">*</span></label>
                        <input type="text" class="form-control w-100 text_s">
                      </div>
                      <div class="form-group col-6 mb-2">
                        <label class="text_s mb-0">Prezime <span style="color: red">*</span></label>
                        <input type="text" class="form-control w-100 text_s">
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Email <span style="color: red">*</span></label>
                        <input type="email" class="form-control w-100 text_s">
                      </div>
                      <div class="form-group col-12 mb-2">
                        <label class="text_s mb-0">Vaša poruka</label>
                        <textarea rows="3" cols="200" class="form-control w-100 text_s"></textarea>
                      </div>
                      <div class="col-12">
                        <p class="text_xs">
                          Slanjem pozivnice saglasni ste da osoba koju pozivate ispunjava
                          <router-link class="link" to="/o-platformi/#kako-da-postanete-clan" target="_blank">
                            <u>neophodne uslove za članstvo</u></router-link>.
                        </p>
                      </div>
                      <div class="col-12">
                        <button to="" type="button" class="btn button_red-small mr-2" style="font-size:12px"
                                @click="sendNewInvitation()">
                          Pošalji
                        </button>
                        <router-link type="button" class="btn button_red-cancel" style="font-size:12px" to="" data-toggle="collapse" data-target="#nova-pozivnica" aria-expanded="false" aria-controls="sidebar">
                          Otkaži
                        </router-link>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>

<script>
import {decodeToken} from "@/utils/jwtUtils";
import {protectedApis} from "@/utils/protectedApis";
import Loader from "@/components/shared/Loader";
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table'

export default {
  name: "Feed",
  components: {
    Loader,
    Navbar,
    Footer,
    VueGoodTable
  },
  data() {
    return {
      infoEmail: 'info@serbianresearchers.com',
      infoEmailSubject: encodeURIComponent('Serbian Researchers - kontakt'),
      researcher: {
        researcher_id: null,
        first_name: null,
        profile_image: null,
        country: null,
        city: null,
        position_old: null,
        position_name: null,
        institution_name: null,
        origin: null,
        personal_page: null,
        research_area_name: null,
        research_subarea_name: null,
        work_page: null,
        is_public: null,
        is_newsletter_subscribed: null,
        email: null,
        profile_url: null
      },
      token: null,
      uuid: null,
      columns: [
        {
          label: 'Ime',
          field: 'full_name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Poslato',
          field: 'created',
          dateOutputFormat: 'MMM do yyyy',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
      rows: [],
      isAccountUpdated: false,
      accountMessage: ''
    };
  },
  methods: {
    fetchFeedData(id){
      protectedApis.post(`${process.env.VUE_APP_API_URL}/me/feed`, {id: id})
          .then(response => {
            this.researcher = response.data.researcher;
            this.rows = response.data.researcher.invitations;
          })
          .catch(error => {
            //handle fejl potencijalno
          });
    },
    sendNewInvitation(){

    },
    saveAccountInfo(id){
      const data = {
        id: id,
        email: this.researcher.email,
        profile_url: this.researcher.profile_url,
        is_newsletter_subscribed: this.researcher.is_newsletter_subscribed,
        is_public: this.researcher.is_public
      };
      protectedApis.post(`${process.env.VUE_APP_API_URL}/me/account`, data )
          .then(response => {
            this.isAccountUpdated = true;
            this.accountMessage = response.data.description;
          })
          .catch(error => {
            //handle fejl potencijalno
          });
    }
  },
  created() {
    const uuid = decodeToken().decodedToken["researcher_id"];
    if(uuid){
      this.fetchFeedData(uuid);
    }
  },
}
</script>
