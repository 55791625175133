import axios from 'axios';
import { decodeToken, checkAuth } from './jwtUtils';

const protectedApis = axios.create();

protectedApis.interceptors.request.use(config => {
    const { token } = decodeToken();
    if (token && checkAuth()) {
        config.headers = config.headers || {};
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export { protectedApis };
