<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
import Clarity from '@microsoft/clarity';

export default {
	name: 'App',
	data() {
		return {
			routeKey: 0
		}
	},
	created() {
		Clarity.init(process.env.VUE_APP_CLARITY_PROJECT_ID);
	}
}
</script>

<style lang="sass">
  @import "@/sass/index.sass"
</style>
